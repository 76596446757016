import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import restApi from "@/plugins/http";
import constants from "@/common/constants";
import VueMask from "v-mask";
import panZoom from "vue-panzoom";
import { Api } from "@/common/api";
import "@/plugins/moment";

import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import HighchartsVue from "highcharts-vue";

Vue.config.productionTip = false;
Vue.prototype.$restApi = restApi;
Vue.prototype.$constants = constants;
Vue.prototype.$api = new Api();
Vue.use(VueMask);
Vue.use(CoolLightBox);
Vue.use(panZoom);
Vue.use(HighchartsVue, { tagName: "highcharts" });

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
